.spinner {
  position: relative;
  height: 100%;
}

.spinner::after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  background: url('/assets/img/spinner.svg') no-repeat center center;
  background-size: 40px;
  content: '';
}

.spinner.white::after {
  background-image: url('/assets/img/spinner-white.svg');
}
