// scss-lint:disable SelectorFormat, ImportantRule

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

app-map {
  display: block;
  height: 100%;
}
