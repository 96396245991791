/* Customizations to the designer theme should be defined here */

// use borders on all sides in tab menu (main nav)
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link,
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  border-bottom-left-radius: $borderRadius;
  border-bottom-right-radius: $borderRadius;
}

// fix margin of last item (account)
.p-menu {
  .p-menuitem {
    &:last-child {
      margin: $verticalMenuitemMargin;
    }
  }
}

// selected item in panel menu (vessel nav)
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link-active {
  background-color: $color-ci-3;

  .p-menuitem-text {
    color: $primaryColor;
  }
}

.p-button,
.p-checkbox-icon,
.p-menuitem-link {
  font-weight: bold;
}

// use same color for selected items in multiselect as for unselected items since they have a checkbox
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: transparent;
  color: $color-txt;
}

// use default border radius for table header
.p-datatable .p-datatable-thead > tr > th {
  border-radius: $borderRadius;
}
