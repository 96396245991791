:root {
  scrollbar-color: $color-ci-1 white;
  scrollbar-width: thin;
}

html {
  color: $color-txt;
  font-family: "Heebo", sans-serif;
  font-size: 0.9em;
}

body {
  line-height: 1.2;
  overflow: hidden;
}

a {
  color: $color-ci-1-b;
  text-decoration: none;
  transition: color 0.1s linear;

  &:hover {
    color: $color-ci-1-c;
  }
}

h1,
h2,
h3,
h4 {
  color: $color-headline;
  font-family: "Avenir", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

h1 {
  margin: 0 0 1.25rem;
  font-size: 1.75rem;
}

h2 {
  margin: 0 0 1rem;
  font-size: 1.5rem;
}

h3 {
  margin: 0 0 0.75rem;
  font-size: 1.25rem;
}

h4 {
  margin: 0 0 0.5rem;
  font-size: 1rem;
}

img {
  display: block;
  max-inline-size: 100%;
}

ol, ul {
  list-style: none;
  padding: 0;
}

.content {
  height: 100%;
  width: 100%;
  padding: 2rem;
  overflow-y: scroll;
}

.full-screen {
  height: 100svh;
}

.gap-inner {
  gap: 1rem;
}

.gap-grid {
  gap: 2rem;
}

.separator {
  border-bottom: solid 1px $color-ci-2-c;
}

.p-default {
  padding: 1rem;
}

.phor-default {
  padding-left: 1rem;
  padding-right: 1rem;
}

.scrollbar-white {
  scrollbar-color: white $color-ci-1;
}

.spin {
  animation: spin 0.6s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.table-icon-link {
  border-radius: 50%;
  color: $color-ci-1;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    color: $color-ci-1;
    background-color: $color-bg;
  }
}
