@font-face {
  font-family: "albis-icons";
  src: url('albis-icons.eot?t=1731414875339'); /* IE9*/
  src: url('albis-icons.eot?t=1731414875339#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url("albis-icons.woff2?t=1731414875339") format("woff2"),
  url("albis-icons.woff?t=1731414875339") format("woff"),
  url('albis-icons.ttf?t=1731414875339') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('albis-icons.svg?t=1731414875339#albis-icons') format('svg'); /* iOS 4.1- */
}

[class^="albis-icons-"], [class*=" albis-icons-"] {
  font-family: 'albis-icons' !important;font-size: 1.4rem;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.albis-icons-antenna:before { content: "\ea01"; }
.albis-icons-calendar:before { content: "\ea02"; }
.albis-icons-check:before { content: "\ea03"; }
.albis-icons-chevron-down:before { content: "\ea04"; }
.albis-icons-cloud:before { content: "\ea05"; }
.albis-icons-download:before { content: "\ea06"; }
.albis-icons-info-circle:before { content: "\ea07"; }
.albis-icons-maximize:before { content: "\ea08"; }
.albis-icons-menu-dots:before { content: "\ea09"; }
.albis-icons-minimize:before { content: "\ea0a"; }
.albis-icons-route:before { content: "\ea0b"; }
.albis-icons-ship:before { content: "\ea0c"; }
.albis-icons-spinner:before { content: "\ea0d"; }
.albis-icons-x:before { content: "\ea0e"; }
.albis-icons-zoom-in:before { content: "\ea0f"; }
.albis-icons-zoom-out:before { content: "\ea10"; }

