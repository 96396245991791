
$color-ci-1: #003b79; // blue
$color-ci-1-b: #4c75a1;
$color-ci-1-c: #99b1c9;
$color-ci-1-d: #d9e2eb;

$color-ci-3: #faba00; // yellow
$color-ci-3-b: #fbcf4c;
$color-ci-3-c: #fde399;
$color-ci-3-d: #feeebf;

$color-ci-7: #00927a; // green
$color-ci-7-b: #4cb2a2;
$color-ci-7-c: #99d3ca;
$color-ci-7-d: #d9efeb;

$color-ci-2: #404040; // grey
$color-ci-2-b: #797979;
$color-ci-2-c: #b3b3b3;
$color-ci-2-d: #e3e3e3;

$color-ci-5: #d40a18; // red
$color-ci-5-b: #e1535d;
$color-ci-5-c: #ee9da3;
$color-ci-5-d: #f9dadd;

$color-ci-4: #70ac2e; // meadow-green
$color-ci-4-b: #9bc56d;
$color-ci-4-c: #c6deab;
$color-ci-4-d: #eaf3e3;

$color-ci-6: #e3e3e3; // TODO not present anymore
$color-ci-6-a: #ececec;
$color-ci-6-b: #f6f6f6;
$color-ci-6-c: #ffffff;

$color-state-info: $color-ci-2-b;
$color-state-ok: $color-ci-4;
$color-state-warning: $color-ci-3;
$color-state-alarm: $color-ci-5;

$color-report-departure: $color-ci-3-c;
$color-report-arrival: $color-ci-4-b;
$color-report-noon: $color-ci-1-c;

$color-bg: $color-ci-6-c;
$color-bg-content: $color-ci-6-b;

$color-txt: #1b2226;
$color-txt-light: $color-ci-6-c;
$color-headline: $color-ci-1;

$color-nav-txt: $color-txt-light;
$color-nav-bg: $color-ci-1;
$color-nav-bg-hover: $color-ci-1-c;
$color-nav-bg-active: $color-ci-1-b;

$color-link: #ff0;
$color-link-hover: #ff0;
$color-link-active: $color-link;
$color-link-active-hover: $color-link-hover;
$color-link-visited: $color-link;
$color-link-visited-hover: $color-link-hover;

$color-chart-light: $color-ci-2-c;
$color-chart-dark: #e0ebf3;
$color-chart-blue: $color-ci-1;
$color-chart-green: $color-state-ok;
$color-chart-red: $color-state-alarm;
$color-chart-yellow: $color-state-warning;

$color-form-input-bg: $color-ci-6-b;
$color-form-input-txt: $color-txt;
$color-form-input-label: #575757;

$color-btn: $color-ci-1;
$color-btn-txt: $color-txt-light;
$color-btn-hover: $color-ci-1-b;

$color-btn-shy: #ced6dc;
$color-btn-shy-txt: $color-form-input-label;
$color-btn-shy-hover: lighten($color-btn-shy, 5);

$color-map-water: #a2bbd5;
$color-map-land: #ffeac4;

.border-color-ci-6 {
  border-color: $color-ci-6;
}

.color-ci-1 {
  color: $color-ci-1;
}

.color-ci-1-b {
  color: $color-ci-1-b;
}

.color-ci-2-b {
  color: $color-ci-2-b;
}
