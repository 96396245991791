@font-face {
  font-family: "Avenir";
  src: url('../assets/fonts/Avenir-LT-Std-65-Medium.otf') format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Heebo";
  src: url('../assets/fonts/Heebo-VariableFont_wght.ttf') format("truetype-variations");
  font-weight: 100 900;
  font-style: normal;
  font-display: fallback;
}
