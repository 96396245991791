.vessel-states {
  .vessel-state-count {
    height: 20px;
    width: 20px;
    overflow: hidden;
    border-radius: 100%;
    color: $color-txt-light;
    font-size: 11px;
    line-height: 20px;
    text-align: center;
  }

  .vessel-state-info .vessel-state-count {
    background: $color-state-info;
  }

  .vessel-state-warning .vessel-state-count {
    background: $color-state-warning;
  }

  .vessel-state-alarm .vessel-state-count {
    background: $color-state-alarm;
  }
}
