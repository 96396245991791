.highcharts-container {
  .highcharts-tooltip-headline,
  .highcharts-tooltip-row {
    display: block;
    margin-bottom: .5em;
  }

  .highcharts-tooltip-headline {
    font-weight: bold;
  }

  .highcharts-tooltip-icon {
    display: inline-block;
    height: 10px;
    width: 10px;
    margin-right: .5em;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: baseline;

    &-line,
    &-scatter {
      border-radius: 100%;
    }

    &-column {
      border-radius: 2px;
    }
  }

  svg {
    position: relative;

    .highcharts-legend {
      display: none;
    }
  }

  .highcharts-legend {
    z-index: 10;
  }

  .highcharts-tooltip {
    z-index: 20;

    > span {
      &::before {
        position: absolute;
        top: -10px;
        right: -10px;
        bottom: -10px;
        left: -10px;
        z-index: -1;
        border-radius: 5px;
        background: rgba(255, 255, 255, .9);
        content: '';
      }
    }
  }

  .highcharts-legend-item {
    > span {
      padding: 0 10px 0 15px;
      //line-height: 1.2;
    }

    &.highcharts-legend-item-hidden {
      > span {
        opacity: .25;
      }
    }
  }

  .highcharts-line-series {
    .highcharts-legend-icon {
      border-radius: 100%;
    }
  }

  .highcharts-column-series {
    .highcharts-legend-icon {
      border-radius: 2px;
    }
  }

  .highcharts-legend-icon {
    display: block;
    position: absolute;
    top: 3px;
    left: 0;
    height: 10px;
    width: 10px;
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: baseline;
  }
}
